import React, { Component } from "react"
import { Modal, Col, Row } from "react-bootstrap"
import { StaticQuery, graphql } from "gatsby"
import ZigzagImage from "./zigzag-image"
import FlavorSquare from "./flavor-square"

class CakeFlavors extends Component {
  state = {
    popup: false,
  }

  togglePopup = () => {
    this.setState({
      popup: !this.state.popup,
    })
  }
  render = () => (
    <StaticQuery
      query={graphql`
        query CakeFlavorsImageQuery {
          markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
            frontmatter {
              flavorsImage {
                childImageSharp {
                  fluid(maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <div>
            <Row className={"align-items-center my-5"}>
              <Col lg="4" className={"mb-4 mb-mb-0"}>
                <ZigzagImage
                  fluid={data.markdownRemark.frontmatter.flavorsImage.childImageSharp.fluid}
                  alt="Cake Flavours"
                />
              </Col>
              <Col lg="8">
                <h5>Cake Flavors</h5>
                <p>
                  For our custom cakes, explore our exquisite collection of cake
                  flavors specially created by Bunty Mahajan. Our philosophy - A
                  cake must taste as good as it looks
                </p>
                <p className="text-md-left text-center">
                  <button
                    onClick={this.togglePopup}
                    className={
                      "btn text-uppercase btn-primary btn-rounded fw-bold"
                    }
                  >
                    View Flavours
                  </button>
                </p>
              </Col>
            </Row>
            <Modal show={this.state.popup} onHide={this.togglePopup} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Cake Flavours</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FlavorSquare />
              </Modal.Body>
            </Modal>
          </div>
        )
      }}
    />
  )
}

export default CakeFlavors
