import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import bmSignature from "../../static/images/bm-signature-large.png"
import BackgroundImage from "gatsby-background-image"

const BuntyBackground = ({ image, text, className }) => (

        <BackgroundImage Tag="section" className={className} fluid={image.childImageSharp.fluid} critical={true}>
          <div className={"darkened"}>
            <div className={"container"}>
              <p>{text}</p>
              <img src={bmSignature} alt="Bunty Mahajan"  loading="lazy" />
            </div>
          </div>
        </BackgroundImage>
      );

const BuntyBackgroundSection = styled(BuntyBackground)`
  width: 100%;
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
`

export default BuntyBackgroundSection
