import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col } from "react-bootstrap"
import MarkdownContent from "../components/markdown"
import Img from "gatsby-image"
import InstagramSlider from "../components/instagram-slider"
import CategoryImage from "../components/category-image"
import RegularImage from "../components/regular-image"
import BuntyBackgroundSection from "../components/bunty-background"
import GiftingBackgroundSection from "../components/gifting-background"
import SecondInstagramSlider from "../components/second-instagram-slider"
import CakeFlavors from "../components/cake-flavors"
import Helmet from "react-helmet"
import HomepageRows from "../components/homepage-rows"

const IndexPageTemplate = ({
  firstSquare,
  secondSquare,
  hashtag,
  orderText,
  about,
  rows,
  firstText,
  secondText,
  aboutBunty,
  aboutImage,
  buntyImage,
}) => (
  <Layout>
    <Seo title="Deliciae by Bunty Mahajan - Voted Best Patisserie in Mumbai" />
    <Helmet>
      <html lang="en" />
      <script type="application/ld+json">{`
		{
			"@context": "https://schema.org",
			"@type": "Bakery",
			"name": "Deliciae cakes",
			"image": "https://www.deliciaecakes.com/style/images/art/about13.jpg",
			"@id": "",
			"url": "https://www.deliciaecakes.com/",
			"telephone": "9029023000",
			"menu": "",
			"servesCuisine": "",
			"acceptsReservations": "false",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "Khar West",
				"addressLocality": "mumbai",
				"postalCode": "400052",
				"addressCountry": "IN"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": 19.073181,
				"longitude": 72.831208
			},
			"sameAs": [
				"https://www.facebook.com/delcakes.in/",
				"https://twitter.com/deliciaecakes",
				"https://www.instagram.com/delcakes.in/"
			]
		}
	`}</script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Deliciae cakes",
            "url": "https://www.deliciaecakes.com/",
            "logo": "https://www.deliciaecakes.com/style/images/logo-light.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "9029023000",
              "contactType": "customer service",
              "areaServed": "IN",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.instagram.com/delcakes.in/",
              "https://www.facebook.com/delcakes.in/",
              "https://twitter.com/deliciaecakes"
            ]
          }
        `}
      </script>
      <meta name="p:domain_verify" content="00fa05f980ec56a96b5717746a3982e3" />
      <meta
        property="og:image"
        content={"https://www.deliciaecakes.com/images/social-preview.png"}
      />
      <meta
        name="facebook-domain-verification"
        content="io7qzuq77jtu84tbiv5ltbcgmjqrxm"
      />
    </Helmet>
    <div className={"container-fluid"}>
      <div className={"row py-4 category-row"}>
        <div className={"col-lg-2 col-6 mb-md-3 mb-2 pr-0"}>
          <div
            className={
              "homepage-panel bg-primary h-100 d-flex align-items-center flex-column justify-content-center"
            }
          >
            <strong className="montserrat text-dark text-uppercase">
              {orderText}
            </strong>
            <a
              className={"btn btn-dark btn-rounded mt-3"}
              href="https://orders.delcakes.in"
            >
              ORDER ONLINE
            </a>
          </div>
        </div>
        <div className={"col-lg-4 col-6 mb-md-3 mb-2 pl-2 pr-md-1"}>
          <CategoryImage
            critical={true}
            imgName={firstSquare}
            alt="Wedding Cakes"
          />
        </div>
        <div className={"col-lg-4 col-6 mb-md-3 mb-2 pr-0 pl-md-1"}>
          <CategoryImage critical={true} imgName={secondSquare} alt="Gifting" />
        </div>
        <div className={"col-lg-2 col-6 mb-md-3 mb-2 pl-2"}>
          <div className="homepage-panel bg-primary h-100 d-flex align-items-center flex-column justify-content-center">
            <Link
              className={"btn btn-dark btn-rounded mb-4"}
              to="/custom-cakes"
            >
              CUSTOM CAKES
            </Link>
            <Link
              to="/categories/wedding-cakes"
              className={"btn btn-dark btn-rounded"}
            >
              WEDDING CAKES
            </Link>
            <img
              src="/images/badge-2024.png"
              className={"white-badge mt-3 mt-md-5"}
              alt="Times Food Awards"
            />
          </div>
        </div>
      </div>
    </div>
    <div className={"light-bg-90 py-5"}>
      <div className={"container-fluid"}>
        <h2 className={"text-center mb-0"}>
          {hashtag}
        </h2>
      </div>
    </div>
    <div className="instagram-swiper">
      <div className="follow-me-on-instagram">
        <a
          href="https://www.instagram.com/delcakes.in"
          target="_blank"
          rel="noreferrer noopener"
        >
          Follow us on Instagram
        </a>
      </div>
      <InstagramSlider />
    </div>
    <div className={"light-bg py-5"}>
      <div className={"container-fluid"}>
        <h5 className={"my-5 text-center"}>ABOUT DELICIAE</h5>
        <div className={"container"}>
          <Row>
            <Col lg="5" className={"mb-3 mb-md-0"}>
              <Img
                fluid={aboutImage.childImageSharp.fluid}
                alt="About Deliciae"
              />
            </Col>
            <Col lg="7">
              <MarkdownContent content={about} className={"montserrat"} />
              <div className={"social-icons"}>
                <a
                  href="https://www.facebook.com/delcakes.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </a>
                <a
                  href="https://www.instagram.com/delcakes.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <BuntyBackgroundSection
      image={buntyImage}
      text={firstText}
      className={
        typeof window !== "undefined" &&
          window.navigator.userAgent &&
          /iPad|iPhone|iPod/.test(window.navigator.userAgent)
          ? "bunty-mahajan-message"
          : "bunty-mahajan-message no-ios"
      }
    />
    <div className={"light-bg py-5"}>
      <div className={"container"}>
        <h5 className={"my-5 text-center"}>ABOUT BUNTY MAHAJAN</h5>
        <MarkdownContent content={aboutBunty} className={"montserrat"} />
      </div>
    </div>
    <div className={"light-bg py-5"}>
      <div className={"container"}>
        <h3 className={"mb-4 text-center"}>#DeliciaeMoments</h3>
        <SecondInstagramSlider />
      </div>
    </div>
    <div className={"light-bg py-5"}>
      <div className={"container montserrat zig-zags"}>
        <h5 className={"mb-5 text-center"}>OUR OFFERINGS IN A NUTSHELL</h5>
        <HomepageRows rows={rows} />
        <CakeFlavors />
      </div>
    </div>
    <GiftingBackgroundSection
      text={secondText}
      className={
        typeof window !== "undefined" &&
          window.navigator.userAgent &&
          /iPad|iPhone|iPod/.test(window.navigator.userAgent)
          ? "gifting-message"
          : "gifting-message no-ios"
      }
    />
    <div className={"other-light-bg py-5"}>
      <div className={"container"}>
        <h5 className={"text-center mb-5"}>GET IN TOUCH</h5>
        <div className={"text-center contact-text"}>
          <p>For Custom & Wedding Cakes, order on WhatsApp - 9029023000.</p>
          <p>
            Order Gourmet Cakes, Gift Boxes & more online at{" "}
            <a
              href="https://orders.delcakes.in"
              rel="noopener noreferrer"
              target="_blank"
              className="clickable-text"
            >
              orders.delcakes.in
            </a>
          </p>
        </div>
        <Row className={"my-5"}>
          <Col lg="4" className={"text-center mb-5 mb-md-0"}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://g.page/delcakes?share"
            >
              <FontAwesomeIcon
                icon="map"
                size="4x"
                className={"mb-5 mb-md-0"}
              />
              <br />
              Deliciae by Bunty Mahajan,
              <br />
              Shop No 11, Gasper Enclave,
              <br />
              Opp. Gold's Gym,
              <br />
              Bandra West
              <br />
              Mumbai - 400050
            </a>
          </Col>
          <Col lg="4" className={"text-center mb-5 mb-md-0"}>
            <FontAwesomeIcon
              icon="phone"
              size="4x"
              className={"mb-5 mb-md-0"}
              transform="rotate-90"
            />
            <br />
            <a href="tel:9029023000">902-902-3000</a>
          </Col>
          <Col lg="4" className={"text-center mb-5 mb-md-0"}>
            <FontAwesomeIcon
              icon="envelope"
              size="4x"
              className={"mb-5 mb-md-0"}
            />
            <br />
            <a
              href="mailto:info@deliciaecakes.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@deliciaecakes.com
            </a>
          </Col>
        </Row>
      </div>
    </div>
  </Layout>
)

IndexPageTemplate.propTypes = {
  about: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate
      firstSquare={frontmatter.firstSquare}
      secondSquare={frontmatter.secondSquare}
      hashtag={frontmatter.hashtag}
      orderText={frontmatter.orderText}
      firstText={frontmatter.firstText}
      secondText={frontmatter.secondText}
      about={frontmatter.about}
      rows={frontmatter.rows}
      aboutBunty={frontmatter.aboutBunty}
      aboutImage={frontmatter.aboutImage}
      buntyImage={frontmatter.buntyBackground}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage
export const query = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        about
        hashtag
        firstSquare {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        secondSquare {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        aboutImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        buntyBackground {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        firstText
        orderText
        secondText
        aboutBunty
        rows {
          title
          description
          buttonText
          buttonURL
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
